$ = jQuery;
String.prototype.replaceAll = function(search, replacement) {
	var target = this;
	return target.split(search).join(replacement);
};

var $log = function(msg) {
		console.log(msg);
	},
	App = {
		init: function() {

			this.ajax_page.init();
			this.scripts();

		},
		ajax_page: {

			classes: {
				active: "current-menu-item current-page-ancestor current-menu-ancestor current-menu-parent current-page-parent current_page_item active",
				page: ".content-area:first",
				lang: "#lang_sel_click",
				menu: ".main-menu",
				loader_target: ".loading-bar"

			},

			current_request: null,

			init: function() {

				if (typeof(history.pushState) === "function") {

					// if (!$("body").hasClass("logged-in") && !$("body").hasClass("admin-bar")) {

					$(window).on("popstate", function(event) {

						App.ajax_page.load(history.state.real_url, true);

					});

					App.ajax_page.links($(".sidebar, .content-area"));

					// }
				}

			},

			load: function(href, dont_change_state) {

				var loader_target = $(App.ajax_page.classes.loader_target).css("width", 0);

				if (App.ajax_page.current_request) {

					App.ajax_page.current_request.abort();

				}

				if (!dont_change_state) {

					history.pushState({
						real_url: href,
						previous_scroll_position: (window.scrollY ? window.scrollY : pageYOffset)
					}, "", href);

				}

				if ($(window).width() <= 768) {

					if ($("body").hasClass("opened")) {
						$(".sidebar-inner .menu-trigger").trigger("click");
					}

				}

				App.ajax_page.current_request = $.ajax({
					type: "GET",
					url: href,
					cache: true,
					error: function(xhr, ajaxOptions, thrownError) {

						if (xhr.statusText != "abort") {

							window.location.href = href;

						}

					},
					xhr: function() {

						var xhr = new window.XMLHttpRequest();

						xhr.addEventListener("progress", function(evt) {
							if (evt.lengthComputable) {
								var percentComplete = evt.loaded / evt.total;
								loader_target.css("width", Math.round(percentComplete * 100) + "%");
							}
						}, false);

						return xhr;

					},
					beforeSend: function() {
						loader_target.addClass("load-start").css("width", "25%");
					},
					complete: function() {
						loader_target.addClass("load-done").css({
							"width": "100%"
						});
					},
					success: function(data) {

						var transition_events = "transitionend webkitTransitionEnd oTransitionEnd";

						App.ajax_page.current_request = false;

						loader_target.css("width", "100%").one(transition_events, function() {

							loader_target.addClass('load-out').removeClass('load-start load-done').css({
								"width": "0%"
							});
							setTimeout(function() {
								loader_target.removeClass('load-out');
							}, 700);

						});

						var current_page = $(App.ajax_page.classes.page),
							current_menu = $(App.ajax_page.classes.menu),
							current_lang = $(App.ajax_page.classes.lang),
							empty_doc = document.implementation.createHTMLDocument(""),
							page_data = $($.parseHTML(data, empty_doc, true)),
							page_wrap = page_data.find(App.ajax_page.classes.page),
							page_lang = page_data.find(App.ajax_page.classes.lang),
							page_menu = page_data.find(App.ajax_page.classes.menu).find(".current-menu-item, .current-page-parent");

						if (page_data.find(".gform_wrapper").length) {

							window.location.href = href;

						}

						if (page_data.filter("title").length) {

							document.title = page_data.filter("title").text();

						}

						if (current_lang.length && page_lang.length) {

							current_lang.replaceWith(page_lang);

						}

						current_page.one(transition_events, function() {
							
							window.scrollTo(0, 0);

							if (page_menu.length) {

								var active_classes_find = "." + App.ajax_page.classes.active.replaceAll(" ", ", .");

								current_menu.find(active_classes_find).removeClass(App.ajax_page.classes.active);

								page_menu.each(function() {

									var active_item = $(this);

									current_menu.find("#" + active_item.attr("id")).addClass(App.ajax_page.classes.active);

								});

							}

							page_wrap.addClass("fade out");
							current_page.replaceWith(page_wrap);

							$(window).off("scroll");

							if (dont_change_state) {
								window.scrollTo(0, history.state.previous_scroll_position);
							}

							App.ajax_page.links(page_wrap);

							App.scripts();
							// App.sliders();

							$(window).trigger("app.attach_menu");
							$("body").removeClass('noscroll');
							page_wrap.removeClass("fade out");

						}).addClass("fade out");

					}
				});

			},

			links: function(link_elements) {

				$("a[href^='" + base_path + "']", link_elements).not(".wpml-ls-link").each(function(i, o) {

					var active_classes = App.ajax_page.classes.active,
						active_classes_find = "." + active_classes.replaceAll(" ", ", ."),
						$self = $(this),
						parent_menu = $self.parents(App.ajax_page.classes.menu),
						parent = $self.parent(),
						submenu = parent.find("ul.sub-menu"),
						href = $self.attr("href"),
						href_pure = href.replace(base_path, ""),
						make_active = function() {

							if (parent_menu.length) {

								parent_menu.find(active_classes_find).removeClass(active_classes);
								$self.parent().addClass(active_classes);

							}

							$self.parentsUntil(parent_menu, "li").addClass(active_classes);
						}

					if (href_pure.indexOf(".") === -1) {

						if (parent.hasClass("menu-item-has-children") && submenu.length) {

							$self.on("click", function(event) {

								event.preventDefault();
								make_active();

							}); // 

						}
						else {

							$self.on("click", function(event) {

								event.preventDefault();

								App.ajax_page.load(href);

								make_active();

								if ($(App.ajax_page.classes.menu).hasClass('opened')) {
									$(".menu-trigger").click();
								}

							});

						}

					}

				});
			}
		},
		scripts: function() {

			//$('select').selectpicker();
			$('select').selectpicker({
				liveSearch: true,
				maxOptions: 1,
				width: '100%'
			});

			//$('body').addClass('loaded');

			function is_touch_device() {
				return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
			}

			if (is_touch_device()) {
				$('body').addClass('touch');
			}
			else $('body').addClass('no-touch');

			// To top
			$(".back-top").click(function() {
				$('html,body').animate({
					scrollTop: 0
				}, 550);
			});

			// Home scroll
			$(".scroll-down-arrow").click(function(e) {
				e.preventDefault();
				$('html,body').animate({
					scrollTop: $('#home-clients').offset().top
				}, 550);
			});

			var $win = $(window),
				scrollPos = $win.scrollTop(),
				opacityPercent = scrollPos / $win.height();

			if ($('body').hasClass('home')) {
				$win.on('scroll', function(event) {

					scrollPos = $win.scrollTop();

					$('.scroll-down-arrow').css({
						'opacity': 1 - scrollPos * 0.005
					});
				});
			}

			$('.application-form input[type=file]').each(function() {
				var $input = $(this),
					$label = $input.parent().prev('label'),
					labelVal = $label.html();

				$input.on('change', function(e) {
					var fileName = '';

					if (this.files && this.files.length > 1)
						fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
					else if (e.target.value)
						fileName = e.target.value.split('\\').pop();

					if (fileName) {
						$label.html(fileName).addClass('changed');
					}
					else {
						$label.html(labelVal).removeClass('changed');
					}
				});

				// Firefox bug fix
				$input
					.on('focus', function() {
						$input.addClass('has-focus');
					})
					.on('blur', function() {
						$input.removeClass('has-focus');
					});
			});

		},

		mobMenu: function(menuWrapper) {

			var $this = menuWrapper;

			/* toggle main menu nav */
			$(".menu-trigger", $this).on("click", function() {

				$(this).toggleClass("cross");
				menuWrapper.toggleClass("opened");
				$('body').toggleClass("opened noscroll");

			});

			$(window).on("keyup", function(event) {

				if ($('body').hasClass("opened")) {
					switch (event.keyCode) {
						case 27: //esc
							$(".menu-trigger", $this).removeClass("cross");
							menuWrapper.removeClass("opened");
							$('body').removeClass("opened noscroll");
							break;
					}
				}

			});

		}

	};

$(window).on("load", function() {

	App.init();
	App.mobMenu($('.sidebar-inner'));

});
